import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { usePromiseTracker } from "react-promise-tracker";

import { RootState } from "&store/store";
import { AppRouter } from "./App.router";
import { AuthRouter } from "./Auth.router";
import ConfigProvider from "./configProvider";
import Loader from "&styled/loader";

import { loginActions } from "./features/login/login.slice";

type ReduxProps = ConnectedProps<typeof connector>;

const App = (props: ReduxProps) => {
  const { isLoggedIn } = props;
  const { i18n } = useTranslation();
  const { promiseInProgress } = usePromiseTracker();

  useEffect(() => {}, [i18n.language]);
  if (window.self === window.top) {
    return (
      <>
        <ConfigProvider i18n={i18n}>{isLoggedIn ? <AppRouter /> : <AuthRouter />}</ConfigProvider>

        {promiseInProgress && <Loader />}
      </>
    );
  }
  return (
    <div>
      <h2>Please inform team with the reference of the application from where you clicked this link.</h2>
    </div>
  );
};

/**
 * Maps state variables from redux store to props of current component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  isLoggedIn: state.login.isLoggedIn,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  reset: loginActions.reset,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const AppRedux = connector(App);

export { AppRedux as App };
